<template>
  <div class="auth-wrapper auth-v1 px-2">  
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-img
			center
			fluid
			:src="require('@/assets/images/logo/LOGOREUNIBOX.png')"
		/>
		<br>
        <b-card-title class="mb-1">
          Bienvenue sur votre espace client ! 👋 
        </b-card-title>
        <!-- form -->
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mot de passe</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Mot de passe oublié?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Connexion
              </b-button>
            </b-form>
          </validation-observer>
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCard, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
	BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
	  CUI: '',
	  affvalid: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
		this.$refs.loginForm.validate().then(success => {
		if (success) {
		  useJwt.login({
			email: this.userEmail,
			password: this.password,
		  })
			.then(response => {
			  if (response.data.res === 1) {
				const { userData } = response.data.response
				useJwt.setToken(response.data.response.accessToken)
				useJwt.setRefreshToken(response.data.response.refreshToken)
				localStorage.setItem('userData', JSON.stringify(userData))
				this.$ability.update(userData.ability)
				window.Intercom('boot', {
					app_id: 's8ydhpth',
					name: userData.fullName, 
					email: this.userEmail,
					Tel_fixe: userData.C_tel,
					Tel_port: userData.C_port,
					user_id: userData.UI,
					user_hash: userData.user_hash,
					bactifboxea: userData.bactifboxea,
					CONTRAT_BOXEA: userData.CONTRAT_BOXEA,
				})
				// this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
				   this.$router.push('/home') 
					.then(() => {
					  this.$toast({
						component: ToastificationContent,
						position: 'top-right',
						props: {
						  title: `Bonjour ${userData.username || userData.fullName}`,
						  icon: 'CoffeeIcon',
						  variant: 'success',
						  text: 'Vous pouvez commencer à explorer!',
						},
					  })
					})
			  } else {
				this.$toast({
						component: ToastificationContent,
						position: 'top-right',
						props: {
						  title: 'Erreur',
						  icon: 'CoffeeIcon',
						  variant: 'success',
						  text: 'Oups une erreur de saisie',
						},
					  })  
			  }
			})
		}
		})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
